<template>
  <div class="player-container">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: "videoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null,
      loading: false
    }
  },
  mounted() {
    let self = this
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      self.loading = true
    });
    let player = document.getElementsByClassName('video-js')
    if (player) {
      player.forEach(el => {
        if (el.addEventListener) {
          el.addEventListener('contextmenu', function (e) {
            e.preventDefault();
          }, false);
        } else {
          el.attachEvent('oncontextmenu', function () {
            window.event.returnValue = false;
          });
        }
      })
    }

  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<style scoped>

</style>