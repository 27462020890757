<template>
  <div id="video-guide">
    <b-card class="rounded-lg">
      <b-card-text class="text-center">
        <h2>
          {{ $t('videoCard.title') }}
        </h2>
      </b-card-text>
      <Loading v-if="videoLoading"/>
      <div class="mt-4" v-else>
        <div class="grid grid-cols-1 gap-1 lg:grid-cols-3 lg:gap-3">
          <div class="bg-white shadow-lg border rounded" v-for="(video,i) in videoList" :key="i">
            <div class="player-container">
              <video-player :options="video.options"/>
            </div>
            <div class="p-2">
              <p class="text-base lg:text-xl">
                {{ video[getTitleLocale] }}
              </p>
              <p class="text-sm">
                {{ video[getDescriptionLocale] }}
              </p>
            </div>
          </div>
        </div>

      </div>

    </b-card>

  </div>
</template>

<script>
import videoPlayer from "@core/components/help/videoPlayer";
import 'video.js/dist/video-js.css'
import Loading from "@core/components/loading/Loading";
import {mapGetters} from "vuex"

export default {
  name: "videoGuides",
  components: {
    videoPlayer, Loading
  },
  data: function () {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
        responsive: true,
        sources: [
          {
            src: 'https://staging.golfigo.se/media/videos/configuration.mp4',
            type: 'video/mp4'
          }
        ]
      },
      videoList: [],
      videoLoading: true
    }
  },
  computed: {
    getDescriptionLocale: function () {
      return this.GET_LOCALE === 'sv' ? 'swe_description' : 'eng_description'
    },
    getTitleLocale: function () {
      return this.GET_LOCALE === 'sv' ? 'swe_title' : 'eng_title'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    getVideoGuides: function () {
      this.videoLoading = true
      this.$useJwt.fetchVideoGuides().then(res => {
        let videoList = res.data.results.filter(item => item.file_url !== null)
        this.videoList = []
        videoList.map(item => {
          let el = {
            autoplay: false,
            controls: true,
            responsive: true,
            sources: [
              {
                src: item.file_url,
                type: 'video/mp4'
              }
            ]
          }
          let dt = {
            ...item,
            options: el
          }
          this.videoList.push(dt)
        })

        this.videoLoading = false
      }).catch(err => {
        this.videoList = false
      })

    },
  },
  mounted() {
    this.getVideoGuides()
    let player = document.querySelectorAll('#video-guide')
    if (player) {
      player.forEach(el => {
        if (el.addEventListener) {
          el.addEventListener('contextmenu', function (e) {
            e.preventDefault();
          }, false);
        } else {
          el.attachEvent('oncontextmenu', function () {
            window.event.returnValue = false;
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>